<template>
  <div class="raffles-form">
    <a-spin :spinning="loading" :tip="labels.form.loading">
      <ValidationObserver ref="observer">
        <a-form
          :model="form"
          :label-width="80"
          slot-scope="{ validate }"
          @submit.prevent="validate().then(handleSubmit)"
        >
          <a-row
            type="flex"
            justify="space-between"
            :gutter="16"
            :style="{ marginBottom: '15px' }"
          >
            <!-- Title -->
            <a-col :xs="24" :md="12">
              <h2 class="form-title">
                <font-awesome-icon
                  :icon="['fas', 'ticket-alt']"
                  class="anticon"
                  :style="{ marginRight: '5px' }"
                />
                <span>{{ labels.subtitle }}</span>
              </h2>
            </a-col>
            <!-- Actions -->
            <a-col :xs="24" :md="12">
              <a-row
                type="flex"
                justify="end"
                :style="{
                  alignItems: 'flex-end',
                  marginBottom: '0px',
                }"
                v-bind="!isMobile && { gutter: 16 }"
              >
                <a-col v-bind="isMobile && { span: 24 }">
                  <a-button-group>
                    <a-button
                      type="danger"
                      icon="arrow-left"
                      ghost
                      @click="handleCancel"
                      v-bind="isMobile && { block: true, class: 'w-100' }"
                    >
                      <span>{{ labels.form.goBack }}</span>
                    </a-button>
                    <template v-if="canSave">
                      <a-dropdown-button
                        v-if="raffles.length"
                        @click="validate().then(handleSubmit)"
                        type="primary"
                        :loading="submitting"
                        :trigger="['click']"
                        :disabled="!validForm"
                        v-bind="isMobile && { block: true, class: 'w-100' }"
                      >
                        {{ labels.form.save }}
                        <a-menu slot="overlay">
                          <a-menu-item
                            key="saveAndClose"
                            @click="validate().then(() => handleSubmit(true))"
                            :disabled="!validForm"
                          >
                            <a-icon type="save" />{{ labels.form.saveAndClose }}
                          </a-menu-item>
                        </a-menu>
                      </a-dropdown-button>
                    </template>
                    <a-button
                      v-if="canConfirm"
                      :disabled="selectedRaffles.length === 0"
                      type="primary"
                      ghost
                      :loading="confirming"
                      @click="validate().then(handleConfirm)"
                      v-bind="isMobile && { block: true, class: 'w-100' }"
                    >
                      <span>
                        <a-badge
                          v-if="pendingSelectedRaflles.length"
                          :count="pendingSelectedRaflles.length"
                          :number-style="{ backgroundColor: '#52c41a' }"
                        />
                        {{ labels.form.confirm }}
                      </span>
                    </a-button>
                  </a-button-group>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
          <a-row type="flex" justify="start" :gutter="30">
            <a-col :span="24">
              <a-card>
                <a-row
                  type="flex"
                  justify="center"
                  :style="{ alignItems: 'flex-end' }"
                  :gutter="[12, 24]"
                >
                  <a-col :xs="20" :sm="12">
                    <DatePicker
                      v-model="date"
                      :name="labels.form.date.label"
                      :format="dateFormat"
                      :valueFormat="dateFormat"
                      :rules="'required'"
                      :locale="locale"
                      :formitem="{
                        ...formItemLayout,
                        label: labels.form.date.label,
                        labelAlign: 'left',
                      }"
                      :disabledDate="disabledDate"
                    />
                  </a-col>
                  <a-col :xs="4" :sm="3">
                    <a-form-item align="left">
                      <a-button
                        type="primary"
                        icon="search"
                        :loading="loading"
                        @click="handleFetch"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
                <a-row
                  v-if="raffles.length"
                  type="flex"
                  justify="center"
                  :style="{ alignItems: 'center' }"
                  :gutter="[12, 24]"
                >
                  <a-col :span="24">
                    <a-table
                      :columns="columns"
                      :dataSource="raffles"
                      :rowKey="(row, idx) => row?.id || idx"
                      :pagination="false"
                      :scroll="{ x: true }"
                      v-bind="!isCreating ? { rowSelection } : {}"
                    >
                      <template
                        v-for="col in columns"
                        :slot="col.dataIndex"
                        slot-scope="text, record, index"
                      >
                        <div
                          v-if="col.dataIndex === 'lotteries'"
                          :key="col.dataIndex"
                        >
                          <a-row
                            type="flex"
                            justify="center"
                            :gutter="[8, 16]"
                            :style="{ alignItems: 'center' }"
                          >
                            <a-col :span="24"
                              ><span class="text-lottery">{{
                                record.lottery?.name
                              }}</span></a-col
                            >
                            <a-col :span="8">
                              <a-input
                                v-if="canSave"
                                v-model="raffles[index].first_prize"
                                :placeholder="placeholder('first_prize')"
                                :style="{ width: '100%' }"
                                :allowClear="true"
                                :maxLength="2"
                                v-mask="'##'"
                                @input="
                                  onlyNumber($event, 'first_prize', index)
                                "
                              />
                              <template v-else>
                                {{ record.first_prize || "-" }}
                              </template>
                            </a-col>
                            <a-col :span="8">
                              <a-input
                                v-if="canSave"
                                v-model="raffles[index].second_prize"
                                :placeholder="placeholder('second_prize')"
                                :style="{ width: '100%' }"
                                :allowClear="true"
                                :maxLength="2"
                                v-mask="'##'"
                                @input="
                                  onlyNumber($event, 'second_prize', index)
                                "
                              />
                              <template v-else>
                                {{ record.second_prize || "-" }}
                              </template>
                            </a-col>
                            <a-col :span="8">
                              <a-input
                                v-if="canSave"
                                v-model="raffles[index].third_prize"
                                :placeholder="placeholder('third_prize')"
                                :style="{ width: '100%' }"
                                :allowClear="true"
                                :maxLength="2"
                                v-mask="'##'"
                                @input="
                                  onlyNumber($event, 'third_prize', index)
                                "
                              />
                              <template v-else>
                                {{ record.third_prize || "-" }}
                              </template>
                            </a-col>
                            <a-col :span="24">
                              <div class="action-buttons">
                                <a-button-group>
                                  <a-button
                                    v-if="canDelete"
                                    type="danger"
                                    class="clean-button"
                                    :title="labels.form.cleanRow"
                                    @click="handleDelete(record, index)"
                                    block
                                  >
                                    {{ labels.form.cleanRow }}
                                  </a-button>
                                  <a-button
                                    v-if="allowedToProcessWinningPlays"
                                    type="primary"
                                    class="update-button"
                                    :loading="record.id === proccessing"
                                    :disabled="record.id === proccessing"
                                    :title="labels.form.update"
                                    @click="
                                      {
                                        proccessWinningPlays(record);
                                      }
                                    "
                                    block
                                  >
                                    {{ labels.form.update }}
                                  </a-button>
                                </a-button-group>
                              </div>
                            </a-col>
                          </a-row>
                        </div>
                        <div v-else :key="col.dataIndex">
                          <a-input
                            v-if="col.editable && canSave"
                            v-model="raffles[index][col.dataIndex]"
                            :placeholder="placeholder(col.dataIndex)"
                            :style="{ width: '100%' }"
                            :allowClear="true"
                            :maxLength="2"
                            v-mask="'##'"
                            @input="onlyNumber($event, col.dataIndex, index)"
                          />
                          <template v-else>
                            {{ text || "-" }}
                          </template>
                        </div>
                      </template>
                    </a-table>
                  </a-col>
                </a-row>
              </a-card>
            </a-col>
          </a-row>
        </a-form>
      </ValidationObserver>
    </a-spin>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import labels from "@/utils/labels";
import DatePicker from "../../../components/core/VeeValidateForm/DatePicker.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import locale from "ant-design-vue/es/date-picker/locale/es_ES";
import { serverDateFormat } from "@/utils/utils";
import moment from "moment";
import { message } from "ant-design-vue";
import router from "@/router";
import {
  DATE_FORMAT,
  adminPageTitle,
  hasRoles,
  roles,
} from "../../../utils/utils";

const paramDateFormat = "YYYYMMDD";

const initialFormState = {
  date: null,
  lottery: null,
  first_prize: undefined,
  second_prize: undefined,
  third_prize: undefined,
};

export default {
  name: "FormRaffle",
  components: {
    ValidationObserver,
    DatePicker,
  },
  metaInfo: {
    title: adminPageTitle(labels.raffles.title),
    titleTemplate: () => {
      return `${adminPageTitle()} - ${
        router.currentRoute.name.includes("edit")
          ? labels.raffles.pageTitleEditing
          : labels.raffles.pageTitleCreate
      }`;
    },
  },
  filters: {
    ucwords(value) {
      if (!value) return "";
      value = value.toString();
      return value
        .split(" ")
        .map((item) => _.capitalize(item))
        .join(" ");
    },
  },
  data() {
    return {
      locale,
      paramDateFormat,
      serverDateFormat,
      reqDateFormat: DATE_FORMAT.SERVER_DATE,
      labels: labels.raffles,
      exception: labels.exception,
      loading: false,
      submitting: false,
      raffles: [],
      form: _.cloneDeep(initialFormState),
      date: null,
      proccessing: 0,
      selectedRaffles: [],
      confirming: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (
      (to?.params?.date &&
        moment(to.params.date, paramDateFormat, true).isValid()) ||
      !to?.params?.date
    ) {
      next();
    } else {
      message.error(labels.exception.ERROR_NOT_FOUND);
    }
  },
  async created() {
    if (this.$route.params?.date) {
      this.fetchData();
    }
  },
  computed: {
    ...mapGetters("adminAuth", ["getLoggedUser"]),
    ...mapState("layout", ["screenWidth"]),
    pendingSelectedRaflles() {
      return this.selectedRaffles.filter(
        (raffle) => raffle.status === "PENDING"
      );
    },
    isMobile() {
      return this.screenWidth < 600;
    },
    canSave() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_RAFFLES_SAVE]);
    },
    canDelete() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_RAFFLES_DELETE]);
    },
    canConfirm() {
      const hasSomeConfirmation = this.raffles.some(
        (raffle) => raffle.id !== null && raffle.status === "PENDING"
      );

      if (!hasSomeConfirmation) {
        return false;
      }

      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_RAFFLES_CONFIRM]);
    },
    isCreating() {
      return !this.$route.name === "admin.raffles.create";
    },
    allowedToProcessWinningPlays() {
      const { accessRoles } = this.getLoggedUser;
      return hasRoles(accessRoles, [roles.admin.ROLE_PROCESS_WINNING]);
    },
    dateFormat() {
      return DATE_FORMAT.MOMENT_DATE;
    },
    formItemLayout() {
      return {
        labelCol: {
          xs: { span: 12 },
          sm: { span: 10 },
          md: { span: 6 },
          lg: { span: 6 },
        },
        wrapperCol: {
          xs: { span: 12 },
          sm: { span: 14 },
          md: { span: 18 },
          lg: { span: 18 },
        },
      };
    },
    columns() {
      return [
        {
          title: this.labels.form.lotteries,
          dataIndex: "lottery",
          customRender: (text) => `${text?.name || ""}`,
          width: "32%",
          hidden: this.isMobile,
        },
        {
          title: this.labels.form.firstPrize.label,
          dataIndex: "first_prize",
          editable: true,
          scopedSlots: { customRender: "first_prize" },
          width: "20%",
          hidden: this.isMobile,
        },
        {
          title: this.labels.form.secondPrize.label,
          dataIndex: "second_prize",
          editable: true,
          scopedSlots: { customRender: "second_prize" },
          width: "20%",
          hidden: this.isMobile,
        },
        {
          title: this.labels.form.thirdPrize.label,
          dataIndex: "third_prize",
          editable: true,
          scopedSlots: { customRender: "third_prize" },
          width: "20%",
          hidden: this.isMobile,
        },
        {
          title: this.labels.form.delete,
          dataIndex: "action",
          key: "action",
          width: "8%",
          hidden:
            (!this.canDelete && !this.allowedToProcessWinningPlays) ||
            this.isMobile,
          customRender: (text, record, index) => {
            return (
              <div class="action-buttons">
                {this.canDelete && (
                  <a-button
                    type="link"
                    icon="delete"
                    class="action-button-delete"
                    title={this.labels.form.cleanRow}
                    onClick={() => this.handleDelete(record, index)}
                  />
                )}
                {this.allowedToProcessWinningPlays &&
                  ["PROCESSED", "CONFIRMED"].includes(record.status) && (
                    <a-divider type="vertical" />
                  )}
                {this.allowedToProcessWinningPlays &&
                  ["PROCESSED", "CONFIRMED"].includes(record.status) && (
                    <a-button
                      loading={record.id === this.proccessing}
                      disabled={record.id === this.proccessing}
                      type="link"
                      icon="sync"
                      class="action-button-sync"
                      title={this.labels.form.proccessWinningPlays}
                      onClick={() => this.proccessWinningPlays(record)}
                    />
                  )}
              </div>
            );
          },
        },
        {
          title: this.labels.form.lotteries,
          dataIndex: "lotteries",
          scopedSlots: { customRender: "lotteries" },
          hidden: !this.isMobile,
        },
      ].filter((col) => !col.hidden);
    },
    validForm() {
      return !!this.raffles.find(
        (f) => !!f.first_prize || !!f.second_prize || !!f.third_prize
      );
    },
    rowSelection() {
      return {
        onChange: (_selectedRowKeys, selectedRows) => {
          this.selectedRaffles = selectedRows;
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.status !== "PENDING",
            status: record.status,
            defaultChecked: ["CONFIRMED", "PROCESSED"].includes(record.status),
          },
        }),
      };
    },
  },
  methods: {
    ...mapActions("raffles", [
      "fetchRaffle",
      "saveRaffle",
      "deleteRaffleById",
      "patchRaffle",
      "confirmRaffle",
    ]),
    async fetchData() {
      try {
        this.loading = true;
        this.date = this.$moment(
          this.$route.params.date,
          paramDateFormat
        ).format(this.dateFormat);
        this.handleFetch();
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        this.$router.push({ name: "admin.raffles" });
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit(closeAfterSave = false) {
      if (this.submitting) return;
      this.submitting = true;
      let response = null;
      let { raffles, date } = this;
      try {
        if (!this.$moment(date, this.dateFormat, true).isValid())
          throw new Error(this.labels.form.submitDateError);

        response = await this.saveRaffle({
          raffles,
          date: this.$moment(date, this.dateFormat).format(this.reqDateFormat),
        });

        let { data, message } = response;

        if (message) this.$message.success(message);

        this.raffles = data;

        if (closeAfterSave) {
          this.$router.push({ name: "admin.raffles" });
        } else {
          if (!this.$route.params?.date)
            this.$router.push({
              name: "admin.raffles.edit",
              params: {
                date: this.$moment(date, this.dateFormat).format("YYYYMMDD"),
              },
            });
        }
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message
        );
        this.$log.error(error);
      } finally {
        this.submitting = false;
      }
    },
    async handleConfirm() {
      this.$confirm({
        title: this.labels.confirmRaffle.title,
        content: (h) =>
          h("div", { class: "raffles-confirmation" }, [
            h(
              "h4",
              { class: "raffles-confirmation-title" },
              this.labels.confirmRaffle.subtitle
            ),
            h("p", {
              class: "raffles-confirmation-subtitle",
              domProps: {
                innerHTML: this.labels.confirmRaffle.subtitle2,
              },
            }),
          ]),
        okText: this.labels.confirmRaffle.confirmButtonText,
        okType: "primary",
        icon: "warning",
        cancelText: this.labels.confirmRaffle.cancelButtonText,
        okButtonProps: {
          disabled: this.confirming,
          loading: this.confirming,
        },
        onOk: async () => {
          if (this.confirming) return;
          this.confirming = true;
          try {
            const raffles = this.pendingSelectedRaflles.map((raffle) => ({
              id: raffle.id,
            }));
            await this.confirmRaffle({
              raffles,
              date: this.$moment(this.date, this.dateFormat).format(
                this.reqDateFormat
              ),
            });
            this.$message.success(this.labels.confirmRaffle.confirmedSuccess);
            this.fetchData();
          } catch (error) {
            this.$message.error(
              error?.response?.data?.message ||
                error?.response?.message ||
                error.message
            );
          } finally {
            this.confirming = false;
          }
        },
      });
    },
    handleCancel() {
      this.$router.push({ name: "admin.raffles" });
    },
    async handleFetch() {
      if (this.$moment(this.date, this.dateFormat, true).isValid()) {
        this.loading = true;
        try {
          const date = this.$moment(this.date, this.dateFormat).format(
            this.reqDateFormat
          );
          this.raffles = await this.fetchRaffle(date);
        } catch (error) {
          this.$message.error(
            error?.response?.data?.message ||
              error?.response?.message ||
              error.message
          );
          this.$log.error(error);
        } finally {
          this.loading = false;
        }
      } else {
        this.$message.error(this.labels.form.submitDateError);
      }
    },
    handleDelete(record, index) {
      if (this.raffles.length) {
        if (record?.id) {
          // confirm
          this.$confirm({
            title: this.labels.confirm.title,
            content: this.labels.confirm.subtitle2,
            okText: this.labels.confirm.confirmButtonText,
            okType: "danger",
            icon: "delete",
            cancelText: this.labels.confirm.cancelButtonText,
            onOk: async () => {
              try {
                await this.deleteRaffleById(record.id);
                this.$message.success(this.labels.deletedSuccess);
                this.cleanRow(index, true);
              } catch (error) {
                this.$message.error(
                  error?.response?.data?.message ||
                    error?.response?.message ||
                    error.message ||
                    this.labels.deleteError
                );
              }
            },
          });
        } else {
          this.cleanRow(index);
        }
      }
    },
    cleanRow(index, removeId = false) {
      if (this.raffles.length) {
        Object.assign(this.raffles[index], {
          ..._.cloneDeep(this.raffles[index]),
          first_prize: null,
          second_prize: null,
          third_prize: null,
          ...(removeId && { id: null }),
        });
      }
    },
    placeholder(field) {
      switch (field) {
        case "first_prize":
          return this.labels.form.firstPrize.placeholder;
        case "second_prize":
          return this.labels.form.secondPrize.placeholder;
        case "third_prize":
          return this.labels.form.thirdPrize.placeholder;
        default:
          return "";
      }
    },
    disabledDate(current) {
      return current && current > moment().endOf("day");
    },
    async onlyNumber(value = "", field, index = 0) {
      this.raffles[index][field] = await value.target.value.replace(/\D/g, "");
    },
    async proccessWinningPlays(record) {
      try {
        this.proccessing = record.id;
        await this.patchRaffle(record);
        this.$message.success(this.labels.processingSuccess);
      } catch (error) {
        this.$message.error(
          error?.response?.data?.message ||
            error?.response?.message ||
            error.message ||
            this.labels.processingError
        );
      } finally {
        this.proccessing = 0;
      }
    },
    onSelectChange(_selectedRowKeys, selectedRows) {
      this.selectedRaffles = selectedRows;
    },
  },
  watch: {
    "$route.params.date"(value, oldValue) {
      if (value && value !== oldValue) {
        this.fetchData();
      }
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/variable";
.raffles-form {
  .form-title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  .form-icon {
    margin-right: 8px;
  }
  .form-button-group {
    margin-top: 16px;
  }
  .raffle {
    font-family: "Montserrat", sans-serif;
    text-align: center;
    padding: 15px 0;
    &-name {
      font-size: 22px;
      font-weight: bold;
    }
    &-joined {
      font-size: 14px;
      color: color(--gray-1);
      span {
        font-weight: bold;
      }
    }
  }
  .ant-checkbox-wrapper {
    span {
      font-size: 20px !important;
      font-weight: 700;
    }
  }
  .action-buttons {
    .action-button-delete {
      color: color(danger);
    }
  }
}
.ant-input[disabled],
.ant-time-picker-input[disabled],
.ant-input-number-disabled,
.ant-select-disabled .ant-select-selection {
  background-color: color(--white) !important;
  color: rgba(0, 0, 0, 0.65) !important;
}
.ant-btn-group {
  width: 100%;
  .update-button,
  .w-100,
  .clean-button {
    width: 100%;
  }
}
.text-lottery {
  font-weight: 600;
  font-size: 18px;
  color: color(black);
}
</style>
